<template lang="html">
  <div class="app-steam pl3 pr3">
    <div class="app-steam__announcement">
      <h2>2.0 Update Now Available!</h2>
      <Trailer class="announcement-vid" url="https://www.youtube.com/embed/saBZARlGAr8" />
      <a href="https://store.steampowered.com/app/1054550/Phantom_Rose" class="button button--primary button--steam"
        >View on STEAM Store</a
      >
    </div>
    <div class="app-steam__content">
      <div class="cover-img absolute">
        <img src="./assets/cover.jpg" class="block" />
      </div>
      <Intro class="app__intro mx-auto" />

      <Body class="app__body" />

      <Screens class="app__screens" />
      <Specs class="app__specs" />
      <Social class="app__social" />
    </div>
  </div>
</template>

<script>
import Specs from './components/Specs.vue'
import Social from './components/Social.vue'
import Screens from './components/Screens.vue'
import Intro from './components/Intro.vue'
import Body from './components/Body.vue'
import Trailer from './components/Trailer.vue'
export default {
  components: {
    Intro,
    Body,
    Screens,
    Specs,
    Social,
    Trailer,
  },
}
</script>

<style lang="scss">
.app__intro,
.app__body,
.app__trailer,
.app__screens,
.app__social {
  max-width: 120rem;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    max-width: 100%;
  }
}

.app__body {
  margin: 55rem auto 0;
  animation: fadeIn 4000ms ease;
  @media screen and (max-width: $breakpoint-lg) {
    margin: 50% auto 0;
  }
}

.app__specs,
.app__screens {
  margin: 10% auto 0;
}

.app__social {
  margin-top: 6%;
  margin-bottom: 6%;
}

.cover-img {
  bottom: 0;
  left: 0;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    content: '';
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    height: 10%;
  }

  img {
    width: 100%;
  }
}

.app-steam__content {
  position: relative;
}

.app-steam__announcement {
  background: rgb(43, 6, 6);
  position: relative;
  z-index: 1;
  padding: 3rem;
  text-align: center;
  margin: 2rem 0;
  border-top: 0.3rem solid rgb(152, 12, 12);

  @media screen and (max-width: $breakpoint-sm) {
    padding: 2rem 1rem;
  }

  h2 {
    margin-top: 0;
  }

  .announcement-vid {
    max-width: 80rem;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
}
</style>
