var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screens relative"},[_c('h2',{staticClass:"caps"},[_vm._v("Screenshots")]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
      navbar: false,
      title: false,
      zoomable: false,
      rotatable: false,
      scalable: false,
      movable: false,
      toolbar: { reset: 0, prev: 1, next: 1 },
    }),expression:"{\n      navbar: false,\n      title: false,\n      zoomable: false,\n      rotatable: false,\n      scalable: false,\n      movable: false,\n      toolbar: { reset: 0, prev: 1, next: 1 },\n    }"}],staticClass:"screens__gallery mxn2 clearfix relative"},_vm._l((_vm.images),function(image){return _c('div',{key:image.src,staticClass:"screens__img-content mb3 px2 sm-col sm-col-12 md-col-6 lg-col-4"},[_c('div',{staticClass:"screens__img"},[_c('img',{attrs:{"src":image.src,"alt":image.title}})])])}),0),_c('h2',{staticClass:"caps"},[_vm._v("Key Art")]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
      navbar: false,
      title: false,
      zoomable: false,
      rotatable: false,
      scalable: false,
      movable: false,
      toolbar: { reset: 0, prev: 1, next: 1 },
    }),expression:"{\n      navbar: false,\n      title: false,\n      zoomable: false,\n      rotatable: false,\n      scalable: false,\n      movable: false,\n      toolbar: { reset: 0, prev: 1, next: 1 },\n    }"}],staticClass:"screens__gallery mxn2 clearfix relative"},_vm._l((_vm.images2),function(image){return _c('div',{key:image.src,staticClass:"screens__img-content mb3 px2 sm-col sm-col-12 md-col-6 lg-col-4"},[_c('div',{staticClass:"screens__img",class:{ 'screens__img--landscape': image.landscape }},[_c('img',{attrs:{"src":image.src,"alt":image.title}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }